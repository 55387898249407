<template>
    <div style="display: flex; justify-content: center; align-items: center; min-height: calc(100vh - 60px); padding: 30px;">
        <b-card style="width: 100%; max-width: 1100px; margin: 0 auto;">
            <div class="text-center mb-2">
                <loading v-show="pageLoading" />
                <div v-show="!pageLoading">
                    <b-row class="mt-5" style="padding-left: 15px; padding-right: 15px;">
                        <!-- Logo Section -->
                        <b-col cols="12" class="d-flex justify-content-center align-item-center mb-5">
                            <div class="logo-wrapper">
                                <img src="../../../assets/img/BAU_Logo.png" height="130" />
                            </div>
                        </b-col>
                        <!-- Header Text -->
                        <b-col cols="12" class="d-flex justify-content-center align-item-center mb-5">
                            <h2 class="text-center">{{ $t('room_reservation_system') }}</h2>
                        </b-col>
                    </b-row>

                    <!-- Form Section -->
                    <ValidationObserver ref="formReservationModalValidate">
                        <b-card :sub-title="$t('personal_info')">
                            <b-row>
                                <b-col xs="12" sm="6">
                                    <ValidationProvider name="name" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('name')">
                                            <b-form-input v-model="formAddress.name" :class="errors[0] ? 'is-invalid' : ''" />
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="6">
                                    <ValidationProvider name="surname" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('surname')">
                                            <b-form-input v-model="formAddress.surname" :class="errors[0] ? 'is-invalid' : ''" />
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="6">
                                    <ValidationProvider name="gender" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('gender')">
                                            <b-form-input v-model="formData.gender" readonly :class="errors[0] ? 'is-invalid' : ''" />
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="6">
                                    <ValidationProvider name="email" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('email')">
                                            <b-form-input v-model="formData.email" readonly :class="errors[0] ? 'is-invalid' : ''" />
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="4">
                                    <ValidationProvider name="mobile_tel_country_code" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('country_code')">
                                            <multiselect
                                                v-model="formAddress.mobile_tel_country_code"
                                                :options="countryCodeData"
                                                track-by="text"
                                                label="text"
                                                @input="selectedCountryCode"
                                                :searchable="true"
                                            />
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="8">
                                    <ValidationProvider name="mobile_tel" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('phone_number')">
                                            <b-form-input v-mask="mask" v-model="formAddress.mobile_tel" :class="errors[0] ? 'is-invalid' : ''" />
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="6">
                                    <ValidationProvider name="passport_country_id" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('passport_country')">
                                            <country-selectbox v-model="formAddress.passport_country_id" :public="true" :validateError="errors[0]" />
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="6">
                                    <ValidationProvider name="passport_number" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('passport_number')">
                                            <b-form-input v-model="formAddress.passport_number" :class="errors[0] ? 'is-invalid' : ''" />
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                        </b-card>

                        <!-- Address Information -->
                        <b-card :sub-title="$t('address_information')" class="mt-3">
                            <b-row>
                                <b-col xs="12" sm="4">
                                    <ValidationProvider name="address_city_id" rules="" v-slot="{ valid, errors }">
                                        <b-form-group>
                                            <label>{{ $t('city_name') }}</label>
                                            <city-selectbox v-model="formAddress.address_city_id" :validateError="errors[0]" />
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="4">
                                    <ValidationProvider name="address_district_id" rules="" v-slot="{ valid, errors }">
                                        <b-form-group>
                                            <label>{{ $t('city') }}</label>
                                            <district-outline-selectbox v-model="formAddress.address_district_id" :city_id.sync="formAddress.address_city_id" :validateError="errors[0]" />
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="4">
                                    <ValidationProvider name="address_zip_code" rules="" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('zip_code')">
                                            <b-form-input v-model="formAddress.address_zip_code" :class="errors[0] ? 'is-invalid' : ''" />
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                        </b-card>

                        <!-- Reservation Information -->
                        <b-card :sub-title="$t('reservation_information')" class="mt-3">
                            <b-row>
                                <b-col xs="12" sm="6">
                                    <ValidationProvider name="check_in_date" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('check_in_date')">
                                            <b-form-input v-model="formData.check_in_date" readonly :class="errors[0] ? 'is-invalid' : ''" />
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="6">
                                    <ValidationProvider name="check_out_date" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('check_out_date')">
                                            <b-form-input v-model="formData.check_out_date" readonly :class="errors[0] ? 'is-invalid' : ''" />
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="4">
                                    <ValidationProvider name="housing_building_name" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('building')">
                                            <b-form-input v-model="formData.housing_building_name" readonly :class="errors[0] ? 'is-invalid' : ''" />
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="4">
                                    <ValidationProvider name="housing_room_name" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('room')">
                                            <b-form-input v-model="room" readonly :class="errors[0] ? 'is-invalid' : ''" />
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="4">
                                    <b-form-group :label="$t('bed')">
                                        <b-form-input v-model="formData.bed_name" readonly/>
                                    </b-form-group>
                                </b-col>
                                <b-col xs="12" sm="4">
                                    <ValidationProvider name="housing_floor" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('floor')">
                                            <b-form-input v-model="formData.housing_floor" readonly :class="errors[0] ? 'is-invalid' : ''" />
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="4">
                                    <ValidationProvider name="type_name" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('type')">
                                            <b-form-input v-model="formData.type_name" readonly :class="errors[0] ? 'is-invalid' : ''" />
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="4">
                                    <ValidationProvider name="fee" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('price')">
                                            <b-form-input v-model="formData.fee" readonly :class="errors[0] ? 'is-invalid' : ''" />
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                        </b-card>
                    </ValidationObserver>

                    <!-- Submit Button -->
                    <div class="d-flex justify-content-center mt-3">
                        <b-button @click="saveReservationForm()">
                            {{ $t('submit') }}
                        </b-button>
                    </div>
                </div>
            </div>
        </b-card>
    </div>
</template>

<script>
    // Component
	import CitySelectbox from "@/components/interactive-fields/CitySelectbox";
	import DistrictOutlineSelectbox from "@/components/interactive-fields/DistrictOutlineSelectbox";
	import CountrySelectbox from "@/components/interactive-fields/CountrySelectbox";
    import reservationSystemFormService from '@/services/reservationSystemFormService'
    import {mapGetters} from "vuex";


    // Services
    // Pages
    import Loading from '@/components/elements/Loading'

    import {setRoutePage} from "@/modules/reservationSuccessPage/router/middleware"


    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import countryCodeInputData from "./countryCodeInputData"


    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
            Loading,
            CitySelectbox,
            DistrictOutlineSelectbox,
            CountrySelectbox
        },
        metaInfo() {
        return {
            title: this.$t("room_reservation_form"),
        };
    },
        data() {
            return {
                pageLoading: false,
                mask: '',
                countryCodeInputData,
                formAddress: {},
            }
        },
        computed: {
        countryCodeData() {
                let countryCode = []
                this.countryCodeInputData.forEach(itm => {
                    countryCode.push({
                        text: itm.text,
                        value: itm.value,
                        code: itm.maxlength ?? null
                    })
                })
                return countryCode
            },
            room(){
                return this.formData.housing_room_name+' - '+this.formData.room_type_name;
            },
            ...mapGetters({
            formData: "reservationSystemForm/formData"
            })
        },
        methods: {
        selectedCountryCode(value) {
            const find = this.countryCodeInputData.filter(a => a.value == value.value)
            if (!find) {
                return;
            }
            const maxPhoneNumberLength = parseInt(find[0].maxlength)
            this.mask = `${value.value} ${'#'.repeat(maxPhoneNumberLength)}`;
        },
        async saveReservationForm() {
            const isValid = await this.$refs.formReservationModalValidate.validate();
            if(!isValid){
                return
            }

            this.pageLoading = false;
            this.formData.housing_bed_id=this.formData.id

            let formData = {
                ...this.formAddress,
                ...this.formData
            };
                try {
                    const response = await reservationSystemFormService.save(formData);
                    this.$toast.success(this.$t('api.' + response.data.message));
                    this.$emit('createFormSuccess', true);
                    await  this.$store.dispatch('reservationSystemForm/deleteFormData')
                    setRoutePage(true)
                    this.$router.push('/reservation/success/page');
                } catch (e) {
                    this.showErrors(e, this.$refs.formReservationModalValidate);
                } finally {
                    this.pageLoading = false;
                }
    },
},

    }
</script>
